import React from 'react'
import { CodeForm, TopBar } from '../components'
import { Link } from 'react-router-dom'

const ClientCode = () => {
    return (
        <>
                <TopBar />

            <div className='code-form-data'>
                <h2>We have sent a 6 digit code to your email</h2>
                <p>
                    In order to proceed we need to confirm your identity via email, please enter the code you have received below. If you have not received the email please check your spam and junk folder
                </p>
                <h3>Code</h3>
                <CodeForm />
            </div>
        </>
    )
}

export default ClientCode
