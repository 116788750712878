import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { getData, setData } from '../utils';
import { API_ENDPOINT, AUTH_HEADERS } from '../config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const CodeForm = () => {

    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const [codeStep, setCodeStep] = useState(0);
    const fieldRef = useRef([]);
    const [disable, setDisabled] = useState(true);
    const [clientUserId, setClientUserId] = useState("");
    const [clientId, setClientId] = useState("");
    const navigate = useNavigate();

    const toastMessage = (toastMessage) => {
        toast(toastMessage);
        
    }


    const handleChange = (e, index) => {
        let content = [...code];
        content[index] = e.target.value
        console.log(e.keyCode);
        setCode(content);
        if (index < code.length - 1 && e.target.value.length === 1) {
            fieldRef.current[index + 1].focus()
        }


        if(e.keycode === 8) {
            console.log('hit')
        }
    }


    const backspace = (e, index) => {
        if(e.keyCode === 8 ) {
            e.preventDefault()
            let content = [...code];
            content[index] = "";
            setCode(content);
            if (index > 0 ) {
                fieldRef.current[index - 1].focus()
            }
        }
    }
    

    useEffect(() => {
        console.log(code);
        let emptyData = code.filter((item) => item === '');
        if (emptyData.length > 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [code])

    useEffect(() => {
        getData('clientUserId').then((res) => setClientUserId(res)).catch((error) => navigate('/user-details'));
        getData('clientId').then((res) => setClientId(res)).catch((error) => console.log('error'));
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!disable) {
            console.log(code);
            // console.log(code.replace(/\s/g, ''))
            let obj = {
                otp: code.join('')
            }
            axios.post(`${API_ENDPOINT}/third-party/assessment/user/${clientUserId}/email-verification`, obj,  {
                headers: {
                ...AUTH_HEADERS,
                'clientId': clientId
            }}).then((res) => {
                setData('otp',true);
                navigate('/questions')

            }
            ).catch((error) => {
                console.log(error);
                toastMessage("You have entered a wrong or expired verification code - please try again.");
            });        
        }
    }

    return (
        <div className='user-code-form'>
            <Form onSubmit={handleSubmit} >
                <div>
                    <Form.Group className='d-flex justify-content-between form-group'>
                        {code.map((item, index) => {
                            return <Form.Control
                                type='text'
                                maxLength={1}
                                id="0"
                                // autoFocus={codeStep === index} 
                                value={item}
                                ref={el => fieldRef.current[index] = el}

                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => backspace(e, index)}
                            />
                        })}
                    </Form.Group>
                    <p className='try-again'>Not received the email - <Link to="/user-details">try again</Link> </p>
                </div>
                <Button type='submit' disabled={disable}>
                    Next
                </Button>
            </Form>
            <ToastContainer />
        </div>
    )
}

export default CodeForm
