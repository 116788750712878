import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Images } from '../config'
import CircularButtonImg from './CircularButtonImg'

const AnswerSet = (props) => {

    let answerData = ["", ""];

    const selectAnswer = (item, answerKey) => {
        answerData = props.answersList;
        if (answerKey === 1) {
            answerData[0] = item;
            // setError1(false);
        } else {
            answerData[1] = item;
            // setError2(false);
        }

        // setAnswers(answerData);
    };

    const _renderElement = () => {
        switch (props.answerType) {
            case 'radio':
                return <div className='answer-container question-card'>
                    <h3>Select one answer</h3>
                    {props.answerList.map((item, index) => {
                        return <div id={item.questionOptionId} className={props.id === item.questionOptionId ? `active radio` : 'radio'} key={index} onClick={() => props.answerChange(item.questionOptionAnswerSet, item.questionOptionId)}>
                            {/* {_renderElement(item.questionOptionType, item.questionOptionText)} */}
                            <p>{item.questionOptionText}</p>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip-2">
                                        {item.questionOptionTip}
                                    </Tooltip>
                                }
                            >
                                <img className="icon-hint" src={Images.info} alt="info" />
                            </OverlayTrigger>
                        </div>
                    }
                    )}
                </div>
            case 'text':
                return <div className='answer-container question-card'>
                    <h3>Select one answer</h3>
                    <div className='d-flex justify-content-between flex-wrap'>
                        {props.answerList.map((item, index) => {
                            return <div id={item.questionOptionId} className={props.id === item.questionOptionId ? `active ${item.questionOptionAnswerSet} text` : `text`} key={index} onClick={() => props.answerChange(item.questionOptionAnswerSet, item.questionOptionId)}>
                                {/* {_renderElement(item.questionOptionType, item.questionOptionText)} */}
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip-2">
                                            {item.questionOptionTip}
                                        </Tooltip>
                                    }
                                >
                                    <img className="icon-hint" src={Images.info} alt="info" />
                                </OverlayTrigger>
                                <p>{item.questionOptionText}</p>

                            </div>
                        }
                        )}
                    </div>

                </div>
            case 'donut':
                return <CircularButtonImg
                    answer={props.answerList}
                    selectAnswer={props.answerChange}
                    selectedAnswer={props.answer}
                    answerKey={props.answerList[0].questionOptionAnswerSet}
                    questionId={props.questionID}
                    response={props.response}
                    questionNumber={props.questionNumber}
                    error={props.error}
                    setError={props.setError}
                />
            default:
                return null
        }
    }

    return (
        <>
            {_renderElement()}
        </>
    )
}

export default AnswerSet