import React from "react";

const ErrorPage = () => {
    return <>
        <div className="lp-screen pagepg404 bg-aliceBlue header-minusHeight" style={{ height: '100vh' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <h2 className="pt-5">404 Page Not Found</h2>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ErrorPage;