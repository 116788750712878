import React from 'react'
import { TopBar, UserForm } from '../components'
import { API_ENDPOINT, Images } from '../config'
import { useNavigate } from 'react-router';
import { getData } from '../utils';

const DataCapture = () => {

    const navigate = useNavigate();

    getData('clientId').then((clientId) => {
        console.log(clientId);
    }).catch((error) => {
        navigate('/');
    })
    

    return (
        <>
            <TopBar />
            <div className='data-capture'>
                {/* <img className="infinity" src={Images.LumenInfinityDark} /> */}
                <div className='data-content'>
                    <h2>Thank you for completing your Lumen</h2>
                    <p>Humanising data is an accessible and frictionless way to unlock the unique value of every individual´s transferrable skills, regardless of background or education, so that they may find and/or progress in meaningful employment.</p>
                    <p>By participating in our survey, you'll add your voice to our evidence-based research to progress fair representation of all talent and equal, diverse, and inclusive practices in both recruitment and the workplace.</p>
                    <UserForm />
                </div>
            </div>
        </>
    )
}

export default DataCapture