import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/scss/bootstrap.scss';
import './assets/css/scss/theme.scss';





const App = (props) => {

  return (

    <>
      {props.children}
    </>
  );
}

export default App;
