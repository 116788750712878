import React from 'react'
import { TopBar, UserDetailsForm, UserForm } from '../components'
import { getData } from '../utils'
import { useNavigate } from 'react-router'

const UserDetailScreen = () => {

    const navigate = useNavigate();

    getData('clientId').then((clientId) => {
        // console.log(clientId);
    }).catch((error) => {
        navigate('/');
    })

    return (
        <>
            <TopBar />
            <div className='data-capture'>
                {/* <img className="infinity" src={Images.LumenInfinityDark} /> */}
                <div className='data-content'>
                    <h2>We just need a few details to get started</h2>
                    <p>To proceed we need to know who you are, this information will be kept securely and will not be passed on to any 3rd parties outside of your organisation and Lumenai.</p>
                    <UserDetailsForm />
                </div>
            </div>
        </>
    )
}

export default UserDetailScreen