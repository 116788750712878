import React, { useEffect, useState } from 'react'
import { getData } from '../utils'
import { Link } from 'react-router-dom'

const FooterTemplate = (props) => {

    const [clientId, setClientID] = useState();
    const [clientUrl, setClientUrl] = useState();
    useEffect(() => {
        getData('clientId').then((res) => setClientID(res))
        getData('clientUrl').then((url) => setClientUrl(url));
    }, [])

    return (
        <div className='template-footer'>
            <h4>All done?</h4>
            {props.page !== 'email' ? (
                <p>Please note that if you have not requested “email report” you may lose access to your results.</p>
            ) : null}
            <Link to={clientUrl} className="visit-site">Back to site</Link>
        </div>
    )
}

export default FooterTemplate