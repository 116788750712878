import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import TopBar from './topBar';
import { useNavigate } from 'react-router';
import { getData, setData } from '../utils';
import axios from 'axios';
import { API_ENDPOINT, AUTH_HEADERS } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const UserDetailsForm = () => {

    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [emailSaved, setEmailSaved] = useState("");
    const [formData, setFormData] = useState({
        name: { value: '', error: false },
        email: { value: '', error: false },
        jobTitle: { value: '', error: false },
        department: { value: '', error: false },
        termsandcondition: {value: false, error: false},
    })
    const [emailType, setEmailType] = useState('');

    const toastMessage = (toastMessage) => {
        toast(toastMessage);
        
    }

    const onChange = (e) => {
        const name = e.target.name;
        let isError = false;
        if (e.target.value === '') {
            isError = true;
        }

        let val = e.target.value;
        console.log(emailType, " aaa", val.split('@')[1])
        console.log(val.split('@')[1] === emailType);
        if(emailType !== "any" && val.split('@')[1] === emailType && val.split('@')[1] !== undefined) {
            console.log("hitt")
        }

        if(e.target.type === 'email' && e.target.value === emailSaved) {
            setEmailError('');
        };

        setFormData({ ...formData, [name]: { value: e.target.value, error: isError } })
    }

    useEffect(() => {
        getData('domain').then((res) => {
            console.log(res,'domain')
            setEmailType(res);
        }).catch((error) => console.log(error));
    }, [])

    useEffect(() => {

        let obj = {
            name: formData.name.value,
            email: formData.email.value,
            jobTitle: formData.jobTitle.value,
            department: formData.department.value,
            termsandcondition: formData.termsandcondition.value
        }

        if(emailSaved === formData.email.value) {
            setValidated(false);
        }


        if (validated) {

            getData('clientId').then((clientId) => {

                axios.post(`${API_ENDPOINT}/third-party/assessment/user/details`, obj, {
                    headers: {
                        ...AUTH_HEADERS,
                        'clientid': clientId
                    }
                }).then((clientUserId) => {
                    setData('clientUserId', clientUserId.data.success.data.clientUserDetails.clientUserId)
                    navigate('/client-code');
                    setData('userEmail', formData.email.value);
                    setData('otp',false);

                }
                ).catch((error) => {
                    // toastMessage(error.response.data.error.message);
                    setEmailError(error.response.data.error.message)
                });

            }).catch(() => {
                navigate('/')
            })
        }

    }, [validated])

    const handleSubmit = (e) => {
        
        e.stopPropagation();
        e.preventDefault();
        if (formData.name.value === '' || formData.name.value.length < 3) {
            setFormData((prevState) => ({ ...prevState, ['name']: { value: '', error: true } }))
        }

        if (formData.email.value === '' || !(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(formData.email.value) || (formData.email.value.split('@')[1] !== emailType && emailType !== 'any')) {
            setFormData((prevState) => ({ ...prevState, ['email']: { value: '', error: true } }))
        }

        

        if (formData.jobTitle.value === '' || formData.jobTitle.value.length < 2) {
            setFormData((prevState) => ({ ...prevState, ['jobTitle']: { value: '', error: true } }))
        }

        if (formData.department.value === '' || formData.department.value.length < 2 ) {
            setFormData((prevState) => ({ ...prevState, ['department']: { value: '', error: true } }))
        }

        if (formData.termsandcondition.value === false) {
            setFormData((prevState) => ({ ...prevState, ['termsandcondition']: { value: false, error: true } }))
        }


        if (formData.name.error || formData.name.value === '' || formData.name.value.length < 3 ||
            formData.jobTitle.error || formData.jobTitle.value === '' || formData.jobTitle.value.length < 2 ||
            formData.department.error || formData.department.value === '' || formData.department.value.length < 2 ||
            formData.email.error || formData.email.value === '' || !(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(formData.email.value) ||
            formData.termsandcondition.error || formData.termsandcondition.value === false || (emailError !== "" && emailError === emailSaved) || (formData.email.value.split('@')[1] !== emailType && emailType !== 'any')
        ) {
            setValidated(false);
        } else {
            setEmailError("");
            setEmailSaved(formData.email.value)
            setValidated(true);
        }

    }


    useEffect(() => {
        console.log(formData);
        console.log(formData.jobTitle.value)
        console.log(formData.department.value)
    }, [formData])

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className='d-flex flex-column' controlId="validationCustom01">
                    <Form.Label>
                        Name
                    </Form.Label>
                    <Form.Control required type='text' value={formData.name.value} name="name" onChange={(e) => onChange(e)} />
                    {formData.name.error ? (
                        <>
                        {formData.name.value < 3 ? 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >The name must have atleast 3 character</Form.Control.Feedback>
                        : 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please enter the department</Form.Control.Feedback>
                    }
                        </>
                    ) : null}
                </Form.Group>

                <Form.Group className='d-flex flex-column' controlId="validationCustom01">
                    <Form.Label>
                        Company email
                    </Form.Label>
                    <Form.Control required type='email' value={formData.email.value} name="email" onChange={(e) => onChange(e)} />
                    {formData.email.error || emailError !== "" ? (
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >{emailError ?  "This email has already been used." : 
                        (formData.email.value.split('@')[1] !== emailType && emailType !== "any") ? "This is not a valid email address – please ensure you use your company’s email address" :  "Enter the valid email" }</Form.Control.Feedback>
                    ) : null}
                </Form.Group>

                <Form.Group className='d-flex flex-column' controlId="validationCustom02">
                    <Form.Label>
                        Job title
                    </Form.Label>
                    <Form.Control type='text' value={formData.jobTitle.value} name="jobTitle" required onChange={(e) => onChange(e)} />
                    {formData.jobTitle.error ? (
                        <>
                        {formData.jobTitle.value < 3 ? 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >The job title must have atleast 2 character</Form.Control.Feedback>
                        : 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please enter the department</Form.Control.Feedback>
                    }
                        </>
                    ) : null}
                </Form.Group>

                <Form.Group className='d-flex flex-column' controlId="validationCustom03">
                    <Form.Label>
                        Department
                    </Form.Label>
                    <Form.Control type='text' value={formData.department.value} name="department" required onChange={(e) => onChange(e)} />
                    {formData.department.error ? (
                        <>
                        {formData.department.value < 3 ? 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >The department must have atleast 2 character</Form.Control.Feedback>
                        : 
                        <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please enter the department</Form.Control.Feedback>
                    }
                        </>
                    ) : null}
                </Form.Group>

                <Form.Group className='d-flex flex-row align-items-center mt-5' controlId="validationCustom04">
                    
                <Form.Label className='mb-0 mr-4 check-label align-items-center d-flex'>

                    <Form.Check type='checkbox' className='checkbox-field' name="termsandcondition" required onChange={(e) => setFormData({...formData, termsandcondition: {value: !formData.termsandcondition.value, error: formData.termsandcondition.value}})}  />
                    
                    <span className='checkbox-check'></span>
                    <span>
                    I accept the full <a href='https://www.lumenai.tech/pptc' target='_blank' title='Terms and condition'>Terms and Conditions</a> & <a href='https://www.lumenai.tech/pptc' target='_blank' title='Privacy policy'>Privacy Policy</a>
                    </span>
                    {formData.termsandcondition.error ? (
                        <Form.Control.Feedback className='feedback feedback-error' type="invalid" style={{ display: 'block' }} >Please accept the terms and condition and privacy policy</Form.Control.Feedback>
                    ) : null}
                    </Form.Label>
                </Form.Group>

                <Button type='submit'>
                    Next
                </Button>
              <ToastContainer />

            </Form>
        </>
    )
}

export default UserDetailsForm