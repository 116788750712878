export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const X_AUTHORIZATION = process.env.REACT_APP_X_AUTHORIZATION;
export const AUTH_HEADERS = {
    "X-Authorization": process.env.REACT_APP_X_AUTHORIZATION,
    "Content-Type": "application/json",
};
export const BASEURL = process.env.REACT_APP_BASEURL;

export const StripeKey = process.env.REACT_APP_STRIPE_KEY;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_SUCCESS_URL