import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Images } from '../config'

const QuestionCard = (props) => {
    return (
        <div className='question-card'>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <div>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip-2">
                            {props.tip}
                        </Tooltip>
                    }
                >
                    <img className="icon-hint" src={Images.info} alt="info" />
                </OverlayTrigger>
                <span className='hint'>Question hint</span>

            </div>
        </div>
    )
}

export default QuestionCard