import React, { useEffect, useState } from 'react'
import { getData } from '../utils';
import { API_ENDPOINT, AUTH_HEADERS, Images } from '../config';
import axios from 'axios';
import { NestedPieChartProfile, StripeModal } from '../components';
import { LockIcon } from '../config/images';
import { useNavigate } from 'react-router';


const Paywall = () => {

    const navigate = useNavigate();

    const [profilemeData, setProfileMeData] = useState();
    const [loading, setLoading] = useState(true);
    const [payNow, setPayNow] = useState(false)
    const [toggle, setToggle] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [errorDataModel, setErrorDataModel] = useState();
    const [completedDate, setcompletedDate] = useState();
    const [usedetails, setUserdetails] = useState();
    const [userData, setUserData] = useState();
    const [assessmentdetails, setAssessmentdetails] = useState({});
    const [assessmentdetailsHistory, setAssessmentdetailsHistory] = useState([]);
    const [categoryArray, setcategoryArray] = useState([]);
    const [categoryArrayArr, setcategoryArrayArr] = useState([]);
    const [categoryImageArray, setcategoryImageArray] = useState([]);
    const [initialName, setInitialName] = useState([]);
    const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
    const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
        useState([]);
    const [summaryAttempt, setSummaryAttempt] = useState();
    const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
        []
    );
    const [talentCategory, setTalentCategory] = useState([]);
    const [downloadpdfUrl, setDownloadpdfUrl] = useState("");
    const [managementCategory, setManagementCategory] = useState([]);
    const [coreCategory, setCoreCategory] = useState([]);
    const [trainingCategory, setTrainingCategory] = useState([]);
    const [assessmentRange, setAssessmentRange] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [clientUserId, setClientUserId] = useState('')
    //ALL API START
    // const categoryData = useSelector((state) => state.category);
    // const summaryEmailData = useSelector((state) => state.summaryNewEmail);
    const [displayData, setDisplayData] = useState();
    const [softskill, setSoftSkill] = useState();
    const [taxonomyData, setTaxonomyData] = useState([]);
    const [open, setOpen] = useState(false);
    const [guide, setGuide] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    const [clientData, setClientData] = useState({});

    useEffect(() => {
        getData('clientId').then((clientId) => {
            getData('clientUserId').then((res) => {
                setClientUserId(res);
                setLoading(true)
                axios.get(`${API_ENDPOINT}/third-party/assessment/${res}/summary`, {
                    headers: {
                        ...AUTH_HEADERS,
                        'clientId': clientId,
                    },
                }).then((response) => setProfileMeData(response.data.success), setLoading(false)).catch(setLoading(false), (error) => console.log(error))
            }).catch((err) => {
                navigate('/user-details')
            })

            
        }).catch((error) => {
            navigate('/');
        })

    }, [])

    

    useEffect(() => {
        // if (profilemeData.data.data.questionBankDetails !== undefined) {
        //     let set_date1 = moment(
        //         profilemeData.data.data.questionBankDetails.questionBankCompletedAt
        //     ).format("DD/MM/YYYY");
        //     setcompletedDate(set_date1);
        //     setAssessmentdetails(profilemeData.data.data.questionBankDetails);
        // }
        if (profilemeData?.data.taxonomyData !== undefined) {
            setTaxonomyData(profilemeData?.data.taxonomyData);
        }

        // if (profilemeData.data.data.pdfFileDownloadLink !== undefined) {
        //     setDownloadpdfUrl(profilemeData.data.data.pdfFileDownloadLink);
        // }

        if (profilemeData?.data.categoryArr !== undefined) {
            setcategoryArray(profilemeData.data.categoryArr);
            let newmanagrArray = [];
            let newcoreArray = [];
            let newtalentArray = [];
            let sortedcatArray = profilemeData?.data.categoryArr;

            sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);

            sortedcatArray.map((item, index) => {
                if (item.highlightCategoryCoreSection === true) {
                    newcoreArray.push("#48FFFF");
                } else {
                    newcoreArray.push("#381474");
                }
                if (item.highlightCategoryManagerialSection === true) {
                    newmanagrArray.push("#275EFF");
                } else {
                    newmanagrArray.push("#381474");
                }
                if (item.highlightCategoryTalentedSection === true) {
                    newtalentArray.push("#F904FF");
                } else {
                    newtalentArray.push("#381474");
                }

                let activeLearningIcon = "";
                switch (item.categoryName) {
                    case "Problem Solving":
                        activeLearningIcon = Images.problem_solving;
                        break;
                    case "Critical Thinking":
                        activeLearningIcon = Images.criticalthink_white;
                        break;
                    case "Teamwork":
                        activeLearningIcon = Images.teamwork_white;
                        break;
                    case "Communication":
                        activeLearningIcon = Images.comms_white;
                        break;
                    case "Leadership":
                        activeLearningIcon = Images.leadershp_white;
                        break;
                    case "Time Management":
                        activeLearningIcon = Images.timemgt_white;
                        break;
                    case "Emotional Judgement":
                        activeLearningIcon = Images.emotionaljudge_white;
                        break;
                    case "Creativity":
                        activeLearningIcon = Images.creativity_white;
                        break;
                    case "Flexibility/Adaptability":
                        activeLearningIcon = Images.Flexibilty_active;
                        break;
                    default:
                        activeLearningIcon = Images.infinity;
                }

                categoryImageArray.push(activeLearningIcon);
            });



            setBackgroundColorArraycore(newcoreArray);
            setBackgroundColorArraymanager(newmanagrArray);
            setBackgroundColorArraytalent(newtalentArray);
        }
        if (profilemeData?.data.userDetails !== undefined) {
            setUserdetails(profilemeData.data.userDetails);
            let init = (
                profilemeData?.data.userDetails.first_name.slice(0, 1) +
                profilemeData?.data.userDetails.last_name.slice(0, 1)
            ).toUpperCase();
            setInitialName(init);
        }


        if (categoryArray.length > 0) {
            setTalentCategory([]);
            setManagementCategory([]);
            setCoreCategory([]);
            setTrainingCategory([]);
            categoryArray.map((items, index) => {
                if (items.highlightCategoryTalentedSection) {
                    setTalentCategory((current) => [...current, items])
                } else if (items.highlightCategoryManagerialSection) {
                    setManagementCategory((current) => [...current, items])
                } else if (items.highlightCategoryCoreSection) {
                    setCoreCategory((current) => [...current, items])
                } else {
                    setTrainingCategory((current) => [...current, items])
                }
            })

        }

        if (assessmentdetails.length !== 0) {
            setAssessmentRange(assessmentdetails.questionBankOverallPercentage ? Number(assessmentdetails.questionBankOverallPercentage.replace(/%/g, "")) : "")
        }



    }, [profilemeData]);


    useEffect(() => {
        let resizeTimer;

        const handleResizeStart = () => {
            setResizing(true);
            clearTimeout(resizeTimer);
        };

        const handleResizeEnd = () => {
            setResizing(false);
        };

        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth !== prevWidth) {
                handleResizeStart();
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(handleResizeEnd, 500); // Adjust the debounce time as needed
                setPrevWidth(currentWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup function to remove the event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getPaymentData = () => {
        getData('clientId').then((clientId) => {
            getData('clientUserId').then((userId) => {
                axios.get(`${API_ENDPOINT}/third-party/stripe/${userId}/create-payment-intent`, {
                headers: {
                    ...AUTH_HEADERS,
                    'clientId': clientId,
                },
            }).then((response) => 

            (response.data.success.status_code === 200) ? 
            (setClientData(response.data.success.data), setPayNow(true)) : null) 
            }).catch((error) => {
                navigate('/user-details');
            })
            
       }).catch((error) => navigate('/'))
    }


  return (
    <div>
      <div className='paywall'>
            <div className='d-flex justify-content-center lock-detail-container flex-column flex-md-row  flex-lg-row  align-items-center align-items-lg-end'>
                <div className='lock-data col-md-6 col-lg-5 me-md-4 me-lg-5'>
                    <div>
                    {profilemeData &&
                        profilemeData?.data &&
                        profilemeData?.data &&
                        profilemeData?.data.categoryArr
                        && !resizing ? (
                        <NestedPieChartProfile
                            backgroundColorArraycore={backgroundColorArraycore}
                            backgroundColorArraymanager={
                                backgroundColorArraymanager
                            }
                            backgroundColorArraytalent={
                                backgroundColorArraytalent
                            }
                            categoryImageArray={categoryImageArray}
                            catgoryArray={
                                profilemeData &&
                                profilemeData?.data &&
                                profilemeData?.data &&
                                profilemeData?.data.categoryArr
                            }
                        />
                    ) : null}
                    </div>
                    <div className='d-flex my-3 my-md-3 flex-row flex-md-column align-items-center justify-content-center'>
                        <div className='lock-icon-container'>
                            <img src={Images.LockIcon} alt='Lock icon' />
                        </div>
                        <p className='col-8 text-left text-md-center ms-4 ms-md-0'>Unlock your full report to get in depth analysis of your lumen chart</p>
                    </div>
                </div>
                <div className='lock-description col-md-5 col-lg-5'>
                    <div>
                        <h2>Congratulations</h2>
                        <p>You have unlocked the Unique Value of your Transferrable Skills.</p>
                        <p>Get access to your downloadable report for £2*</p>
                        <button  onClick={() => getPaymentData()}>Unlock report</button>
                    </div>
                    <p>*£.50 goes to support our social venture fund that aims to unlock, for free, the unique value of less advantaged individuals so they too can gain access to opportunity and meaningful employment.</p>
                </div>
            </div>
        </div>
        {payNow ? (
                <StripeModal close={() => setPayNow(false)} show={payNow} clientData={clientData} />
            ) : null}
    </div>
  )
}

export default Paywall
