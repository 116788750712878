import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { API_ENDPOINT, AUTH_HEADERS, StripeKey } from '../config';
import CheckoutForm from './checkout';
import axios from 'axios';
import { getData } from '../utils';

const stripePromise = loadStripe(StripeKey)

const StripeModal = (props) => {


    const appearance = {
        theme: 'night',
        variables: {
          fontFamily: 'Sohne, system-ui, sans-serif',
          fontWeightNormal: '500',
          borderRadius: '8px',
          colorBackground: '#221044',
          colorPrimary: '#845BA6',
          accessibleColorOnColorPrimary: '#1A1B25',
          colorText: 'white',
          colorTextSecondary: 'white',
          colorTextPlaceholder: '#ABB2BF',
          tabIconColor: 'white',
          logoColor: 'dark'
        },
        rules: {
          '.Input': {
            backgroundColor: '#221044',
            border: '1px solid var(--colorPrimary)'
          },
          '.CheckboxLabel': {
            color: '#221044',
          }
        }
      };
    const [options, setOptions] = useState({
        clientSecret: props.clientData.client_secret,
        appearance: appearance,
    })




    // const options = {
    //     // passing the client secret obtained from the server
    //     clientSecret: '{{CLIENT_SECRET}}',
    // };
    // const stripe = useStripe();
    // const element = useElements();

    // const handleSubmit = async (event) => {
    //     console.log
    // }

    return (
        <Modal show={props.show}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Unlock report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                    </Elements>
                </Modal.Body>
                <Modal.Footer className='justify-content-start'>
                    <Button variant="secondary" onClick={() => props.close()} className='modal-close stripe-submit mt-0'>Close</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    )
}

export default StripeModal