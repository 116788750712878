import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import Dropdown from './dropdown'
import { useState } from 'react'
import axios from 'axios'
import { API_ENDPOINT, AUTH_HEADERS } from '../config'
import { useNavigate } from 'react-router'
import { getData, setData } from '../utils'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const UserForm = () => {

  const navigate = useNavigate();
  const [clientID, setClientID] = useState();
  const [payWallEnable, setPayWallEnable] = useState();

  const [formData, setFormData] = useState({

    race: { value: '', error: false, otherVal: '' },
    gender: { value: '', error: false, otherVal: '' },
    ancestry: { value: '', error: false, otherVal: '' },
    neurodiverse: { value: '', error: false, otherVal: '' },
    diagnosis: {value: '', error: false, otherVal: ''},
    marital: { value: '', error: false },
    education: { value: '', error: false },
    jobType: { value: '', error: false },
    income: { value: '', error: false },
    




    // age: { value: '', error: false },

    // name: { value: '', error: false },
    // jobTitle: { value: '', error: false },
    // industry: { value: '', error: false },
    // country: { value: '', error: false },
  })



  const [validated, setValidated] = useState(false);

  const gender = [
    { value: 'Woman', label: 'Woman', type: 'gender' },
    { value: 'Man', label: 'Man', type: 'gender' },
    { value: 'Non-binary or gender diverse', label: 'Non-binary or gender diverse', type: 'gender' },
    { value: 'Self describe', label: 'Self describe', type: 'gender' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'gender' },
  ];

  const raceStatus = [
    { value: 'Asian or Pacific Islander', label: 'Asian or Pacific Islander', type: 'race' },
    { value: 'Black', label: 'Black', type: 'race' },
    { value: 'Hispanic or Latino/a/x', label: 'Hispanic or Latino/a/x', type: 'race' },
    { value: 'Indigenous (e.g. North American Indian Navajo, South American Indian Quechua, Aboriginal or Torres Strait Islander)', label: 'Indigenous (e.g. North American Indian Navajo, South American Indian Quechua, Aboriginal or Torres Strait Islander)', type: 'race' },
    { value: 'Middle Eastern or North African', label: 'Middle Eastern or North African', type: 'race' },
    { value: 'White', label: 'White', type: 'race' },
    { value: 'Self describe', label: 'Self describe', type: 'race' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'race' }
  ]

  const options = [
    { value: '17 or younger', label: '17 or younger', type: 'age' },
    { value: '18-20', label: '18-20', type: 'age' },
    { value: '21-29', label: '21-29', type: 'age' },
    { value: '30-39', label: '30-39', type: 'age' },
    { value: '40-49', label: '40-49', type: 'age' },
    { value: '50-59', label: '50-59', type: 'age' },
    { value: '60 or older', label: '60 or older', type: 'age' },
  ];


  const indsutryOptions = [
    { value: 'IT', label: 'Information Technology', type: 'industry' },
    { value: 'Finance', label: 'Finance', type: 'industry' },
    { value: 'Healthcare', label: 'Healthcare', type: 'industry' },
  ];

  const countries = [
    { value: 'USA', label: 'United States', type: 'country' },
    { value: 'CAN', label: 'Canada', type: 'country' },
    { value: 'GBR', label: 'United Kingdom', type: 'country' },
    { value: 'AUS', label: 'Australia', type: 'country' },
    // Add more countries as needed
  ];

  const maritalStatus = [
    { value: 'Single', label: 'Single', type: 'marital' },
    { value: 'Married', label: 'Married', type: 'marital' },
    { value: 'Divorced', label: 'Divorced', type: 'marital' },
    { value: 'Separated', label: 'Separated', type: 'marital' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'marital' },
  ]

  const educationStatus = [
    { value: 'Some secondary education (high school)', label: 'Some secondary education (high school)', type: 'education' },
    { value: 'Completed secondary education (graduated high school)', label: 'Completed secondary education (graduated high school)', type: 'education' },
    { value: 'Trade/technical/vocational training', label: 'Trade/technical/vocational training', type: 'education' },
    { value: 'Some undergraduate education (college or university)', label: 'Some undergraduate education (college or university)', type: 'education' },
    { value: "Completed undergraduate education", label: "Completed undergraduate education", type: 'education' },
    { value: 'Some postgraduate education', label: 'Some postgraduate education', type: 'education' },
    { value: 'Completed postgraduate education (masters or doctorate)', label: 'Completed postgraduate education (masters or doctorate)', type: 'education' },
  ]

  const incomeStatus = [
    { value: '0-9999', label: '£0 – £9,999', type: 'income' },
    { value: '10,000–19,999', label: '£10,000 – £19,999', type: 'income' },
    { value: '20,000–29,999', label: '£20,000 – £29,999', type: 'income' },
    { value: '30,000–39,999', label: '£30,000 – £39,999', type: 'income' },
    { value: '40,000–49,999', label: '£40,000 – £49,999', type: 'income' },
    { value: '50,000–59,999', label: '£50,000 – £59,999', type: 'income' },
    { value: '60,000–69,999', label: '£60,000 – £69,999', type: 'income' },
    { value: '70,000–79,999', label: '£70,000 – £79,999', type: 'income' },
    { value: '80,000–89,999', label: '£80,000 – £89,999', type: 'income' },
    { value: '90,000–99,999', label: '£90,000 – £99,999', type: 'income' },
    { value: '100,000+', label: '£100,000 or more', type: 'income' },
  ]


  const ancestry = [
    { value: 'Western Europe (e.g. Greece, Sweden, United Kingdom)', label: 'Western Europe (e.g. Greece, Sweden, United Kingdom)', type: 'ancestry' },
    { value: 'Eastern Europe (e.g. Hungary, Poland, Russia)', label: 'Eastern Europe (e.g. Hungary, Poland, Russia)', type: 'ancestry' },
    { value: 'North Africa (e.g. Egypt, Morocco, Sudan)', label: 'North Africa (e.g. Egypt, Morocco, Sudan)', type: 'ancestry' },
    { value: 'Sub-Saharan Africa (e.g. Kenya, Nigeria, South Africa)', label: 'Sub-Saharan Africa (e.g. Kenya, Nigeria, South Africa)', type: 'ancestry' },
    { value: 'West Asia / Middle East (e.g. Iran, Israel, Saudi Arabia,)', label: 'West Asia / Middle East (e.g. Iran, Israel, Saudi Arabia,)', type: 'ancestry' },
    { value: 'South and Southeast Asia (e.g. India, Indonesia, Singapore)', label: 'South and Southeast Asia (e.g. India, Indonesia, Singapore)', type: 'ancestry' },
    { value: 'East and Central Asia (e.g. China, Japan, Uzbekistan)', label: 'East and Central Asia (e.g. China, Japan, Uzbekistan)', type: 'ancestry' },
    { value: 'Pacific / Oceania (e.g. Australia, Papua New Guinea, Fiji)', label: 'Pacific / Oceania (e.g. Australia, Papua New Guinea, Fiji)', type: 'ancestry' },
    { value: 'North America (Canada, United States)', label: 'North America (Canada, United States)', type: 'ancestry' },
    { value: 'Central America and Caribbean (e.g. Jamaica, Mexico, Panama)', label: 'Central America and Caribbean (e.g. Jamaica, Mexico, Panama)', type: 'ancestry' },
    { value: 'South America (e.g. Brazil, Chile, Colombia)', label: 'South America (e.g. Brazil, Chile, Colombia)', type: 'ancestry' },
    { value: 'Self describe', label: 'Self describe', type: 'ancestry' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'ancestry' },



  ]

  const jobTypeStatus = [
    { value: 'Fractional Work', label: 'Fractional Work', type: 'jobType' },
    { value: 'Hybrid Worker', label: 'Hybrid Worker', type: 'jobType' },
    { value: 'Remote Worker', label: 'Remote Worker', type: 'jobType' },
    { value: 'Office Worker', label: 'Office Worker', type: 'jobType' },
    { value: 'Employed, working 1-39 hours per week', label: 'Employed, working 1-39 hours per week', type: 'jobType' },
    { value: 'Employed, working 40 or more hours per week', label: 'Employed, working 40 or more hours per week', type: 'jobType' },
    { value: 'Not employed, looking for work', label: 'Not employed, looking for work', type: 'jobType' },
    { value: 'Not employed, NOT looking for work', label: 'Not employed, Not looking for work', type: 'jobType' },
    { value: 'Retired', label: 'Retired', type: 'jobType' },
    { value: 'Disabled, not able to work', label: 'Disabled, not able to work', type: 'jobType' },
  ]

  const neurodiverseOptions = [
    { value: 'I have received a formal diagnosis', label: 'I have received a formal diagnosis', type: 'neurodiverse' },
    { value: 'I am seeking or awaiting a formal diagnosis', label: 'I am seeking or awaiting a formal diagnosis', type: 'neurodiverse' },
    { value: 'I am not seeking a formal diagnosis', label: 'I am not seeking a formal diagnosis', type: 'neurodiverse' },
    { value: 'I am not neurodiverse', label: 'I am not neurodiverse', type: 'neurodiverse' },
    { value: 'Self describe', label: 'Self describe', type: 'neurodiverse' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'neurodiverse' },
  ]

  const diagnosisOptions = [
    { value: 'Autism', label: 'Autism', type: 'diagnosis' },
    { value: 'ADHD', label: 'ADHD', type: 'diagnosis' },
    { value: 'Dyslexia', label: 'Dyslexia', type: 'diagnosis' },
    { value: 'Dyspraxia', label: 'Dyspraxia', type: 'diagnosis' },
    { value: 'Dyscalculia', label: 'Dyscalculia', type: 'diagnosis' },
    { value: 'Self describe', label: 'Self describe', type: 'diagnosis' },
    { value: 'Prefer not to disclose', label: 'Prefer not to disclose', type: 'diagnosis' },
    { value: 'I am not neurodiverse', label: 'I am not neurodiverse', type: 'diagnosis' },
  ]

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation()

    // if (formData.jobTitle.value === '') {
    //   setFormData((prevState) => ({ ...prevState, ['jobTitle']: { value: '', error: true } }))
    // }

    console.log(formData);

    if (formData.gender.value === '' || formData.gender.otherVal === '') {
      setFormData((prevState) => ({ ...prevState, ['gender']: { value: formData.gender.value, error: true, otherVal: '' } }))
    } else {
      setFormData((prevState) => ({ ...prevState, ['gender']: { value: formData.gender.value, error: false, otherVal: formData.gender.otherVal } }))
    }

    if (formData.ancestry.value === '' || formData.ancestry.otherVal === '') {
      setFormData((prevState) => ({ ...prevState, ['ancestry']: { value: formData.ancestry.value, error: true, otherVal: '' } }))
    } else {
      setFormData((prevState) => ({ ...prevState, ['ancestry']: { value: formData.ancestry.value, error: false, otherVal: formData.ancestry.otherVal } }))
    }

    // if (formData.country.value === '') {
    //   setFormData((prevState) => ({ ...prevState, ['country']: { value: '', error: true } }))
    // // }

    // if (formData.industry.value === '') {
    //   setFormData((prevState) => ({ ...prevState, ['industry']: { value: '', error: true } }))
    // }

    // if (formData.age.value === '') {
    //   setFormData((prevState) => ({ ...prevState, ['age']: { value: '', error: true } }))
    // }

    // if (formData.name.value === '') {
    //   setFormData((prevState) => ({ ...prevState, ['name']: { value: '', error: true } }))
    // }

    if (formData.marital.value === '' ) {
      setFormData((prevState) => ({ ...prevState, ['marital']: { value: formData.marital.value, error: true} }))
    }

    if (formData.education.value === '') {
      setFormData((prevState) => ({ ...prevState, ['education']: { value: '', error: true } }))
    }

    if (formData.income.value === '') {
      setFormData((prevState) => ({ ...prevState, ['income']: { value: '', error: true } }))
    }

    if (formData.jobType.value === '') {
      setFormData((prevState) => ({ ...prevState, ['jobType']: { value: '', error: true } }))
    }

    if (formData.race.value === '' || formData.race.otherVal === '') {
      setFormData((prevState) => ({ ...prevState, ['race']: { value: formData.race.value, error: true, otherVal: '' } }))
    } else {
      setFormData((prevState) => ({ ...prevState, ['race']: { value: formData.race.value, error: false, otherVal: formData.race.otherVal } }))
    }

    if (formData.diagnosis.value === '' || formData.diagnosis.otherVal === '') {
      setFormData((prevState) => ({ ...prevState, ['diagnosis']: { value: formData.diagnosis.value, error: true, otherVal: '' } }))
    } else {
      setFormData((prevState) => ({ ...prevState, ['diagnosis']: { value: formData.diagnosis.value, error: false, otherVal: formData.diagnosis.otherVal } }))
    }

    if (formData.neurodiverse.value === '' || formData.neurodiverse.otherVal === '') {
      setFormData((prevState) => ({ ...prevState, ['neurodiverse']: { value: formData.neurodiverse.value, error: true, otherVal: '' } }))
    } else {
      setFormData((prevState) => ({ ...prevState, ['neurodiverse']: { value: formData.neurodiverse.value, error: false, otherVal: formData.neurodiverse.otherVal } }))
    }

    if (formData.gender.error || formData.gender.value === '' || formData.ancestry.error || formData.ancestry.value === '' ||
      formData.marital.error || formData.marital.value === '' || formData.education.error || formData.education.value === '' ||
      formData.income.error || formData.income.value === '' || formData.jobType.error || formData.jobType.value === '' ||
      formData.race.error || formData.race.value === '' || formData.diagnosis.error || formData.diagnosis.value === '' || 
      formData.neurodiverse.error || formData.neurodiverse.value === '' ||
      formData.gender.otherVal === "" || formData.ancestry.otherVal === '' || formData.race.otherVal === ''
      || formData.diagnosis.otherVal === '' || formData.neurodiverse.otherVal === ''
    ) {
      setValidated(false)
    } else {
      setValidated(true)
    }



  };

  useEffect(() => {
    getData('clientId').then((res) => {setClientID(res); getData('resultData').then((response) => {
      setPayWallEnable(response.payWall);
    })});
  }, [])



  useEffect(() => {
    if (validated) {
      let obj = {
        // name: formData.name.value,
        gender: formData.gender.otherVal ? formData.gender.otherVal : formData.gender.value,
        // jobTitle: formData.jobTitle.value,

        ancestry: formData.ancestry.otherVal ? formData.ancestry.otherVal : formData.ancestry.value,
        // age: formData.age.value,
        // industry: formData.industry.value,
        // country: formData.country.value,
        marital: formData.marital.value,
        education: formData.education.value,
        income: formData.income.value,
        jobType: formData.jobType.value,
        race: formData.race.otherVal ? formData.race.otherVal : formData.race.value,
        neurodiverse: formData.neurodiverse.otherVal ? formData.neurodiverse.otherVal : formData.neurodiverse.value,
        diagnosis: formData.diagnosis.otherVal ? formData.diagnosis.otherVal : formData.diagnosis.value,
      }

      getData('clientUserId').then((clientUserId) => {
        axios.post(`${API_ENDPOINT}/third-party/assessment/user/${clientUserId}/details`, obj, {
          headers: {
            ...AUTH_HEADERS,
            'clientId': clientID,
          },
        }).then((response) => {
          // setData('clientUserId', response.data.success.data.clientUserDetails.clientUserId)
          if(payWallEnable === 1) {
            navigate('/paywall')
          } else {
            navigate('/result')

          }
        }).catch((error) => {
          toast(error)
        })
      })

    }
  }, [validated])


  const onChange = (e) => {
    const name = e.target.name;
    let isError = false;
    if (e.target.value === '') {
      isError = true;
    }

    setFormData({ ...formData, [name]: { value: e.target.value, error: isError } })

  }

  const selectChange = (e) => {
    const name = e.type;
    let isError = false;
    if (e.value === '') {
      isError = true;
    }


    if (e.value === 'Self describe') {
      setFormData({ ...formData, [name]: { value: e.value, error: isError, otherVal: '' } })

    } else {
      setFormData({ ...formData, [name]: { value: e.value, error: isError } })

    }

  }


  // useEffect(() => {
  //   console.log(formData);
  //   console.log(payWallEnable);
  // }, [formData])

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className='d-flex flex-column' controlId="validationCustom02">
          <Form.Label>
            With which gender do you most identify?
          </Form.Label>
          <Select options={gender} name={'gender'} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.gender.value === 'Self describe' ? (
            <Form.Control className='mt-2' type='text' required value={formData.gender.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['gender']: { value: formData['gender'].value, error: formData['gender'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.gender.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }}>Please select the gender</Form.Control.Feedback>
          ) : null}
        </Form.Group>



        <Form.Group className='d-flex flex-column' controlId="validationCustom02">
          <Form.Label>
            What are your ethnic origins or ancestry?
          </Form.Label>
          <Select options={ancestry} name={'ancestry'} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.ancestry.value === 'Self describe' ? (
            <Form.Control className='mt-2' type='text' required value={formData.ancestry.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['ancestry']: { value: formData['ancestry'].value, error: formData['ancestry'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.ancestry.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }}>Please select the ancestry</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
            How would you identify yourself in terms of race?
          </Form.Label>
          <Select options={raceStatus} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.race.value === 'Self describe' ? (
            <Form.Control className='mt-2' type='text' required value={formData.race.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['race']: { value: formData['race'].value, error: formData['race'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.race.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the job type</Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
          What level of education have you achieved?
          </Form.Label>
          <Select options={educationStatus} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.education.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the education</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
            What is your marital status?
          </Form.Label>
          <Select options={maritalStatus} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.marital.value === 'other' ? (
            <Form.Control className='mt-2' type='text' required value={formData.marital.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['marital']: { value: formData['marital'].value, error: formData['marital'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.marital.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the marital status</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        {/* <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
            Which category below includes your age?
          </Form.Label>
          <Select options={options} required onChange={(e) => selectChange(e)} />


          {formData.age.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the age</Form.Control.Feedback>

          ) : null}
        </Form.Group> */}

        <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
            How much combined income did your household earn in 2023?
          </Form.Label>
          <Select options={incomeStatus} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.income.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the income</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        <Form.Group className='d-flex flex-column' controlId="validationCustom04">
          <Form.Label>
            Which of the following categories best describes your employment status?
          </Form.Label>
          <Select options={jobTypeStatus} required onChange={(e) => selectChange(e)} placeholder="Please select" />
          {formData.jobType.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the job type</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        <Form.Group className='d-flex flex-column' controlId="validationCustom05">
          <Form.Label>
          Are you neurodiverse?
          </Form.Label>
          <Select options={neurodiverseOptions} required onChange={(e) => selectChange(e)} placeholder={'Please select'} />
          {formData.neurodiverse.value === 'Self describe' ? (
            <Form.Control className='mt-2' type='text' required value={formData.neurodiverse.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['neurodiverse']: { value: formData['neurodiverse'].value, error: formData['neurodiverse'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.neurodiverse.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the option</Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className='d-flex flex-column' controlId="validationCustom06">
          <Form.Label>
          If you are seeking or have received a formal neurodiversity diagnosis which condition do you identify with?
          </Form.Label>
          <Select options={diagnosisOptions} required onChange={(e) => selectChange(e)} placeholder={'Please select'} />
          {formData.diagnosis.value === 'Self describe' ? (
            <Form.Control className='mt-2' type='text' required value={formData.diagnosis.otherVal} name="name" onChange={(e) => setFormData({ ...formData, ['diagnosis']: { value: formData['diagnosis'].value, error: formData['diagnosis'].error, otherVal: e.target.value } })} placeholder='Specify' />
          ) : null}
          {formData.diagnosis.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the any one option</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        {/* <Form.Group className='d-flex flex-column' controlId="validationCustom01">
          <Form.Label>
            Name
          </Form.Label>
          <Form.Control required type='text' value={formData.name.value} name="name" onChange={(e) => onChange(e)} />
          {formData.name.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Enter the valid name</Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className='d-flex flex-column' controlId="validationCustom03">
          <Form.Label>
            Job title
          </Form.Label>
          <Form.Control type='text' name="jobTitle" required onChange={(e) => onChange(e)} />
          {formData.jobTitle.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please enter the job title</Form.Control.Feedback>
          ) : null}
        </Form.Group>


        <Form.Group className='d-flex flex-column' controlId="validationCustom05">
          <Form.Label>
            Industry
          </Form.Label>
          <Select options={indsutryOptions} onChange={(e) => selectChange(e)} />
          {formData.industry.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the industry</Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group className='d-flex flex-column' controlId="validationCustom06">
          <Form.Label>
            Country
          </Form.Label>
          <Select options={countries} onChange={(e) => selectChange(e)} />
          {formData.country.error ? (
            <Form.Control.Feedback className='feedback' type="invalid" style={{ display: 'block' }} >Please select the country</Form.Control.Feedback>
          ) : null}
        </Form.Group> */}
        <div className='d-flex justify-content-between flex-wrap'>
         
          <Link className='skip' to={payWallEnable === 1 ? '/paywall' : '/result'}>Skip</Link>
          <Button type='submit'>
            Next
          </Button>
        </div>
      </Form>
    </>
  )
}

export default UserForm