import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../config';

const Template = (props) => {

    const __renderContent = (element) => {
        switch (element) {
            case 'start':
                return (
                    <>
                        <Link to={props.location ? '/user-details' : '#'} style={props.location ? { opacity: 1 } : { opacity: 0.6 }} className="button">
                            Get started
                        </Link>
                        <div className='poweredBy'>
                            <span>powered by</span>
                            <img src={Images.UpdatLogo} alt="Lumenai" />
                        </div>
                    </>
                );
            case 'result':
                return (
                    <>
                        <div>
                            <Link to="/view-results" className="button view-result">
                                View results
                            </Link>
                            {props.emailUpdate.userReport === 1 ? (
                                <Link to="/email" className="button">
                                    Email report
                                </Link>
                            ) : null}
                        </div>
                    </>
                );
            case 'email':
                return (
                    <div className='d-flex justify-content-center email-button-container'>
                        <form onSubmit={(e) => props.emailFun(e)}>
                            <div className="form-group mb-4 mb-md-0">
                                <label htmlFor="email">Email address</label>
                                <input type="email" id="email" name="email" value={props.emailVal} onChange={(e) => props.emailChange(e)} />
                            </div>
                            <div className='d-flex justify-content-between flex-wrap '>
                            <Link to="/view-results" className="button view-result">
                                View report
                            </Link>
                            <button type='submit' className='button me-0' onClick={(e) => props.emailFun(e)} >Send email</button>
                            </div>
                        </form>
                        
                    </div>



                )

            default: return null

        }
    }

    return (
        <div className='template'>
            <div className='content'>
                {props.type === 'start' ? (<h3 className='start-title'>Welcome to</h3>) : null}
                {props.type === 'start' || props.type === 'result' || props.type === 'email' ? (
                    <img className='update-logo' src={Images.UpdatLogo} alt="Lumenai" />
                ) : null}
                {/* {props.type === 'result' || props.type === 'email' ? (
                    <img src={Images.BlurLogo} alt='Logo' className='logo' />
                ) : null} */}
                <h2>{props.title}</h2>
                {props.description ? (
                <p>{props.description}</p>
            ) : null}
                <div>
                    {__renderContent(props.type)}
                </div>
            </div>
        </div>
    )
}

export default Template;