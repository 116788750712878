import React from 'react'
import { Images } from '../config'

const TopBar = () => {
    return (
        <div className='top-bar py-4'>
            <img src={Images.UpdatLogo} alt='Logo' />
        </div>
    )
}

export default TopBar