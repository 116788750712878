import React, { useEffect, useState } from 'react'
import { API_ENDPOINT, AUTH_HEADERS, Images, data } from '../config';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AnswerSet, QuestionCard, TopBar } from '../components';
import axios from 'axios';
import { getData, isEmpty, setData } from '../utils';
import { useNavigate } from 'react-router';

const Assessment = () => {

    const navigate = useNavigate();
    const questionData = data.success.data;
    const [questions, setQuestions] = useState();
    const [questionBankId, setQuestionBankId] = useState();
    const [response, setResponse] = useState([]);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [questionId, setQuestionId] = useState();
    const [clientId, setClientId] = useState();
    const [answers, setAnswers] = useState([]);
    const [assessmentResponse, setAssessmentResponse] = useState({
        questionBankId: '',
        clientUserId: '',
        answerResponses: {},
    });
    const [sortedResponse, setSortedResponse] = useState();
    const [questionBankName, setQuestionBankName] = useState();

    useEffect(() => {

        getData('clientId').then((res) => {
            setClientId(res);
            getData('clientUserId').then((clientRes) => {
               getData('otp').then((otp) => {
                if(!otp) {
                    navigate('/client-code');
                } else {
                    axios.get(`${API_ENDPOINT}/third-party/assessment/details`, {
                        headers: {
                            ...AUTH_HEADERS,
                            'clientId': res,
                        },
                    }).then((response) => {
    
    
    
                        setAssessmentResponse({ ...assessmentResponse, clientUserId: clientRes, questionBankId: response.data.success.data.questionBankDetails.questionBankId })
    
                        setQuestionBankId(response.data.success.data.questionBankDetails.questionBankId)
                        setQuestions(response.data.success.data.questionBankDetails.
                            questionBankQuestions);
                        setQuestionBankName(response.data.success.data.questionBankDetails.questionBankName);
                    })
                }
               }).catch((error) => {
                console.log('errr');
               });
                
            }).catch((error) => {
                navigate('/user-details');
            })

        }).catch((error) => {
            navigate('/');
        })

    }, [])

    const answerChange = (set, id) => {
        if (set === 1) {
            let newVal = [...answers];
            newVal[0] = id;
            setAnswers(newVal)
        } else {
            let newVal = [...answers];
            newVal[1] = id;
            setAnswers(newVal);
        }
    }


    const previousQuestions = () => {
        setQuestionNumber(questionNumber - 1);
        let questionID = questions[questionNumber - 1].questionId;
        let data = response[questionNumber - 1];
        setAnswers(data[questionID]);
    }

    // useEffect(() => {
    //     console.log('assessmentResponse', assessmentResponse)
    // }, [assessmentResponse])

    const onSubmit = () => {
        nextQuestion();
        // axios.post(`${API_ENDPOINT}/third-party/me/assessment/responses`, assessmentResponse, {
        //     headers: {
        //         ...AUTH_HEADERS,
        //         'clientId': clientId
        //     }
        // }).then((response) => {
        //     navigate('/result', { state: { email: response.data.success.data.clientDetails.csvEmail, userReport: response.data.success.data.clientDetails.csvEmail } })
        // }

        // ).catch((error) => console.log(error, 'error'))


    }

    useEffect(() => {
        // console.log("answers: ", answers)
        // console.log("answers length: ", answers.length)
    }, [answers])


    const nextQuestion = () => {
        let questionID = questions[questionNumber].questionId;
        setAnswers([]);

        if (response.length > questionNumber) {
            if (response.length > questionNumber + 1) {
                let questionNewID = questions[questionNumber + 1].questionId;
                let val = response[(questionNumber + 1)];
                setAnswers(val[questionNewID], 'data');
            } else {
                setAnswers([]);
            }

            let responseData = [...response];
            let obj = {
            }
            obj[questionID] = answers
            responseData[questionNumber] = obj;
            setResponse(responseData);
            setQuestionNumber(questionNumber < questions.length ? questionNumber + 1 : questionNumber);
        } else {
            setResponse((current) => [...current, { [questionID]: answers }]);
            setQuestionNumber(questionNumber < questions.length - 1 ? questionNumber + 1 : questionNumber);
        }
    }

    useEffect(() => {
        let obj2 = {}
        if (response?.length > 0) {
            response.map((item, index) => {
                obj2[Object.keys(item)] = Object.values(item)[0];
            })
        }

        setSortedResponse(obj2);
        setAssessmentResponse({ ...assessmentResponse, answerResponses: obj2 })
    }, [response])


    useEffect(() => {
        if (questions?.length === Object.keys(assessmentResponse?.answerResponses).length) {
            axios.post(`${API_ENDPOINT}/third-party/me/assessment/responses`, assessmentResponse, {
                headers: {
                    ...AUTH_HEADERS,
                    'clientId': clientId
                }
            }).then((response) => {
                let obj = {
                    email: response.data.success.data.clientDetails.csvEmail,
                    userReport: response.data.success.data.clientDetails.userReport,
                    payWall: response.data.success.data.clientDetails.payWall,
                    // payWall: 1,
                }
                setData('resultData', obj)
                setData('paymentSucceed', false)
                navigate('/data-capture')
            }

            ).catch((error) => console.log(error, 'error'))
        }

    }, [assessmentResponse])

    return (
        <div className='assessment question-container'>
            <TopBar />
            <div className='assessment-content'>
                <div className='assessment-title'>
                    <h2>{questionBankName}</h2>
                    <span className='question-number'>Question {questionNumber + 1} of {questions?.length}</span>
                </div>
                {questions?.map((item, index) => {
                    let answerSet1 = [];
                    let answerSet2 = [];

                    return questionNumber === index ?
                        (
                            <div key={index}>
                                <QuestionCard
                                    title={item.questionContent}
                                    description={'Please select two answers'}
                                    tip={item.questionLumenTip}
                                />
                                {
                                    item.questionOptions.map((answer, index) => {
                                        if (answer.questionOptionAnswerSet === 1) {
                                            answerSet1.push(answer);
                                        } else {
                                            answerSet2.push(answer);
                                        }
                                    })

                                }
                                <div className='d-flex answers-list' style={{ display: 'flex' }}>
                                    <AnswerSet answerList={answerSet1} answerType={answerSet1[0].questionOptionType} answerChange={answerChange} id={answers?.length > 0 ? answers[0] : ''} questionNumber={questionNumber} questionID={item.questionId} answer={response[questionNumber - 1]} response={response} />
                                    <AnswerSet answerList={answerSet2} answerType={answerSet2[0].questionOptionType} answerChange={answerChange} id={answers?.length > 0 ? answers[1] : ''} questionNumber={questionNumber} questionID={item.questionId} answer={response[questionNumber - 1]} response={response} />
                                </div>

                            </div>
                        )

                        : null
                })}

                <div className='button-container'>
                    <button className='prev' disabled={questionNumber <= 0 ? true : false} onClick={() => previousQuestions()}>Previous</button>
                    <button className='next' disabled={answers.length === 2 && !isEmpty(answers[0]) ? false : true} onClick={() => { questionNumber < questions?.length - 1 ? nextQuestion() : onSubmit() }}>{questionNumber < questions?.length - 1 ? 'Next' : 'Submit'}</button>
                </div>
            </div>
        </div>
    )
}

export default Assessment