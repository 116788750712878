import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { API_ENDPOINT, AUTH_HEADERS, PAYMENT_URL } from '../config';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { getData, setData } from '../utils';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const CheckoutForm = () => {

    const stripe = useStripe()
    const elements = useElements();
    const navigate = useNavigate();
    const [clientUserId, setClientUserId] = useState();
    const [clientId, setClientId] = useState();

    useEffect(() => {
      getData('clientUserId').then((clientid) => {
        setClientUserId(clientid);
      })

      getData('clientId').then((clientid) => {
        setClientId(clientid);
      })
    }, [])

    const toastMessage = (toastMessage) => {
      toast(toastMessage);
      
  }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        toastMessage('payment in process');
    
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        

        
    
        const { error, paymentIntent } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect: 'if_required',
          // confirmParams: {
          //   return_url: `${PAYMENT_URL}?session_id={CHECKOUT_SESSION_ID}`,
          // },
        });
        if (error) {
          toastMessage('enter valid card information')
          setData('paymentSucceed', false);
          console.log('hit');
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
          let obj = {
            paymentIntentId: paymentIntent.id,
            paymentStatus: paymentIntent.status,
            paymentResponse: JSON.stringify(paymentIntent),
          }

          setData('paymentSucceed', true);

          axios.post(`${API_ENDPOINT}/third-party/assessment/user/${clientUserId}/storePaymentDetails`, obj,  {headers: {
            ...AUTH_HEADERS,
            'clientid': clientId
        }}).then((res) => {
        toastMessage('Payment done successfully');
        setTimeout(() => {
          navigate('/result')
        }, 2000)
       
      }
        ).catch((error) => toast(error));
          
          
          // handleSuccess();
        } else {
          toastMessage('Payment failed please try again')
          setData('paymentSucceed', false);
          // handleOther();
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button className='stripe-submit' disabled={!stripe}>Submit</button>
            <ToastContainer />
        </form>
    )
}

export default CheckoutForm