import React from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from '../App';
import Start from '../screens/start';
import Email from '../screens/email';
import Results from '../screens/results';
import DataCapture from '../screens/dataCapture';
import Assessment from '../screens/assessment';
import ViewResults from '../screens/viewResults';
import ErrorPage from '../screens/errorPage';
import UserDetailScreen from '../screens/userDetailScreen';
import Paywall from '../screens/paywall';
import ClientCode from '../screens/clientCode';

const AppNavigation = (props) => {
    return (
        <App>
            <>
                <Router>
                    <Routes>
                        <Route path="*" element={<ErrorPage />} />
                        <Route path="/" element={<Start />} />
                        <Route path="/c/:clientId" element={<Start />} />
                        <Route path="/email" element={<Email />} />
                        <Route path="/result" element={<Results />} />
                        <Route path="/data-capture" element={<DataCapture />} />
                        <Route path="/questions" element={<Assessment />} />
                        <Route path="/view-results" element={<ViewResults />} />
                        <Route path="/user-details" element={<UserDetailScreen />} />
                        <Route path="/paywall" element={<Paywall />} />
                        <Route path="/client-code" element={<ClientCode />} />
                    </Routes>
                </Router>
            </>

        </App>
    )
}

export default AppNavigation