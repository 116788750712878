import React, { useEffect, useState } from 'react'
import { summaryData } from '../config/data'
import NestedPieChartProfile from '../components/NestedPieChartProfile'
import moment from 'moment';
import { API_ENDPOINT, AUTH_HEADERS, Images } from '../config';
import { CategoryShowList, FooterTemplate, ProgressCard, StripeModal } from '../components';
import { Container } from 'react-bootstrap';
import { guide__icon, leveltwo, trainingrequire, twolevelinfo, levelthree, infinity, flexibiltyDark, emotionaljudgeDark, creativityDark, criticalthinkingDark, timemgtDark, teamworkDark, leadershipDark, communicationDark, search, probsolvingDark, showkey__icon, cross__icon } from '../config/images';
import axios from 'axios';
import { getData } from '../utils';
import { Link, useNavigate } from 'react-router-dom';

const ViewResults = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [profilemeData, setProfileMeData] = useState();
    const [toggle, setToggle] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [errorDataModel, setErrorDataModel] = useState();
    const [completedDate, setcompletedDate] = useState();
    const [usedetails, setUserdetails] = useState();
    const [userData, setUserData] = useState();
    const [assessmentdetails, setAssessmentdetails] = useState({});
    const [assessmentdetailsHistory, setAssessmentdetailsHistory] = useState([]);
    const [categoryArray, setcategoryArray] = useState([]);
    const [categoryArrayArr, setcategoryArrayArr] = useState([]);
    const [categoryImageArray, setcategoryImageArray] = useState([]);
    const [initialName, setInitialName] = useState([]);
    const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
    const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
        useState([]);
    const [summaryAttempt, setSummaryAttempt] = useState();
    const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
        []
    );
    const [talentCategory, setTalentCategory] = useState([]);
    const [downloadpdfUrl, setDownloadpdfUrl] = useState("");
    const [managementCategory, setManagementCategory] = useState([]);
    const [coreCategory, setCoreCategory] = useState([]);
    const [trainingCategory, setTrainingCategory] = useState([]);
    const [assessmentRange, setAssessmentRange] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [clientUserId, setClientUserId] = useState('')
    //ALL API START
    // const categoryData = useSelector((state) => state.category);
    // const summaryEmailData = useSelector((state) => state.summaryNewEmail);
    const [displayData, setDisplayData] = useState();
    const [softskill, setSoftSkill] = useState();
    const [taxonomyData, setTaxonomyData] = useState([]);
    const [open, setOpen] = useState(false);
    const [guide, setGuide] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    const [clientData, setClientData] = useState({});
    const [clientUrl, setClientUrl] = useState('');
    const [clientId, setClientId] = useState('');

    useEffect(() => {
        let resizeTimer;

      

        const handleResizeStart = () => {
            setResizing(true);
            clearTimeout(resizeTimer);
        };

        const handleResizeEnd = () => {
            setResizing(false);
        };

        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth !== prevWidth) {
                handleResizeStart();
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(handleResizeEnd, 500); // Adjust the debounce time as needed
                setPrevWidth(currentWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup function to remove the event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        getData('resultData').then((res) => {
            getData('paymentSucceed').then((payment) => {
                if(res.payWall === 1 && !payment) {
                    navigate('/paywall');
                }
            }).catch((error) => {
                navigate('/questions')
            })
        }).catch((error) => {
            navigate('/questions')
        })

        getData('clientId').then((clientId) => {
            setClientId(clientId);
            getData('clientUserId').then((res) => {
                setClientUserId(res);
                setLoading(true)
                axios.get(`${API_ENDPOINT}/third-party/assessment/${res}/summary`, {
                    headers: {
                        ...AUTH_HEADERS,
                        'clientId': clientId,
                    },
                }).then((response) => setProfileMeData(response.data.success), setLoading(false)).catch(setLoading(false), (error) => console.log(error))
            }).catch((error) => {
                navigate('/user-details')
            })

            getData('clientUrl').then((url) => setClientUrl(url));

            // axios.get(`${API_ENDPOINT}/third-party/stripe/create-payment-intent`, {
            //     headers: {
            //         ...AUTH_HEADERS,
            //         'clientId': clientId,
            //     },


            // }).then((response) => setClientData(response.data.success.data));
        }).catch((error) => {
            navigate('/');
        })

    }, [])

    // useEffect(() => {
    //     // console.log(profilemeData)
    // }, [profilemeData])

    const handleToggle = () => {
        setOpen(!open);
        setGuide(false);
        document.documentElement.classList.remove("position__fixed");
    }

    useEffect(() => {
        if (profilemeData?.data.questionBankDetails !== undefined) {
            let set_date1 = moment(
                profilemeData?.data.questionBankDetails.questionBankCompletedAt
            ).format("DD/MM/YYYY");
            setcompletedDate(set_date1);
            setAssessmentdetails(profilemeData?.data.questionBankDetails);
        }
        if (profilemeData?.data.taxonomyData !== undefined) {
            setTaxonomyData(profilemeData?.data.taxonomyData);
        }

        // if (profilemeData.data.data.pdfFileDownloadLink !== undefined) {
        //     setDownloadpdfUrl(profilemeData.data.data.pdfFileDownloadLink);
        // }

        if (profilemeData?.data.categoryArr !== undefined) {
            setcategoryArray(profilemeData.data.categoryArr);
            let newmanagrArray = [];
            let newcoreArray = [];
            let newtalentArray = [];
            let sortedcatArray = profilemeData?.data.categoryArr;

            sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);

            sortedcatArray.map((item, index) => {
                // console.log("cat item", item);
                if (item.highlightCategoryCoreSection === true) {
                    newcoreArray.push("#48FFFF");
                } else {
                    newcoreArray.push("#381474");
                }
                if (item.highlightCategoryManagerialSection === true) {
                    newmanagrArray.push("#275EFF");
                } else {
                    newmanagrArray.push("#381474");
                }
                if (item.highlightCategoryTalentedSection === true) {
                    newtalentArray.push("#F904FF");
                } else {
                    newtalentArray.push("#381474");
                }

                let activeLearningIcon = "";
                switch (item.categoryName) {
                    case "Problem Solving":
                        activeLearningIcon = Images.problem_solving;
                        break;
                    case "Critical Thinking":
                        activeLearningIcon = Images.criticalthink_white;
                        break;
                    case "Teamwork":
                        activeLearningIcon = Images.teamwork_white;
                        break;
                    case "Communication":
                        activeLearningIcon = Images.comms_white;
                        break;
                    case "Leadership":
                        activeLearningIcon = Images.leadershp_white;
                        break;
                    case "Time Management":
                        activeLearningIcon = Images.timemgt_white;
                        break;
                    case "Emotional Judgement":
                        activeLearningIcon = Images.emotionaljudge_white;
                        break;
                    case "Creativity":
                        activeLearningIcon = Images.creativity_white;
                        break;
                    case "Flexibility/Adaptability":
                        activeLearningIcon = Images.Flexibilty_active;
                        break;
                    default:
                        activeLearningIcon = Images.infinity;
                }

                categoryImageArray.push(activeLearningIcon);
            });



            setBackgroundColorArraycore(newcoreArray);
            setBackgroundColorArraymanager(newmanagrArray);
            setBackgroundColorArraytalent(newtalentArray);
        }
        if (profilemeData?.data.userDetails !== undefined) {
            setUserdetails(profilemeData.data.userDetails);
            let init = (
                profilemeData?.data.userDetails.first_name.slice(0, 1) +
                profilemeData?.data.userDetails.last_name.slice(0, 1)
            ).toUpperCase();
            setInitialName(init);
        }

    }, [profilemeData]);

    useEffect(() => {
        if (assessmentdetails.length !== 0) {
            setAssessmentRange(assessmentdetails.questionBankOverallPercentage ? Number(assessmentdetails.questionBankOverallPercentage.replace(/%/g, "")) : "")
        }
    }, [assessmentdetails])


    useEffect(() => {
        if (categoryArray.length > 0) {
            setTalentCategory([]);
            setManagementCategory([]);
            setCoreCategory([]);
            setTrainingCategory([]);
            categoryArray.map((items, index) => {
                if (items.highlightCategoryTalentedSection) {
                    setTalentCategory((current) => [...current, items])
                } else if (items.highlightCategoryManagerialSection) {
                    setManagementCategory((current) => [...current, items])
                } else if (items.highlightCategoryCoreSection) {
                    setCoreCategory((current) => [...current, items])
                } else {
                    setTrainingCategory((current) => [...current, items])
                }
            })

        }
    }, [categoryArray])

    return (
        <div className='view-results'>
            {loading ? (
                <div style={{ color: '#fff', padding: '3rem' }}>
                    <h1>  Loading....</h1>
                </div>
            ) : (
                <>
                    <div className='container pt-5'>
                        <h3 className='text-white progress-heading'>Your soft skill report</h3>
                    </div>

                    <div className='d-flex justify-content-center chart-container flex-wrap'>

                        <div className='all-progress col-11 d-flex align-items-center justify-content-between align-items-start'>
                            <div className="category-progress-summary col-8 category__block1">

                                <ProgressCard
                                    statusText={"Completed " + moment(assessmentdetails.questionBankCompletedAt).format("DD/MM/YYYY")}
                                    icon={Images.assessment_white}
                                    title={assessmentdetails.questionBankName}
                                    levelData={assessmentdetails.questionBankLevel}
                                    percentSymbol
                                    percent={
                                        assessmentdetails.questionBankOverallPercentage &&
                                        assessmentdetails.questionBankOverallPercentage.slice(
                                            0,
                                            assessmentdetails.questionBankOverallPercentage
                                                .length - 1
                                        )
                                    }
                                    categoryCorePercentage={
                                        assessmentdetails.questionBankCorePercentage &&
                                        assessmentdetails.questionBankCorePercentage.slice(
                                            0,
                                            assessmentdetails.questionBankCorePercentage
                                                .length - 1
                                        )
                                    }
                                    categoryManagerialPercentage={
                                        assessmentdetails.questionBankManagerialPercentage &&
                                        assessmentdetails.questionBankManagerialPercentage.slice(
                                            0,
                                            assessmentdetails.questionBankManagerialPercentage
                                                .length - 1
                                        )
                                    }
                                    categoryTalentedPercentage={
                                        assessmentdetails.questionBankTalentedPercentage &&
                                        assessmentdetails.questionBankTalentedPercentage.slice(
                                            0,
                                            assessmentdetails.questionBankTalentedPercentage
                                                .length - 1
                                        )
                                    }

                                    type={'percent'}
                                />
                            </div>
                            <p className='level-data'>Level 1</p>
                        </div>

                        <div className="pie-chart col-md-5 col-lg-5">
                            {profilemeData &&
                                profilemeData?.data &&
                                profilemeData?.data &&
                                profilemeData?.data.categoryArr
                                && !resizing ? (
                                <NestedPieChartProfile
                                    backgroundColorArraycore={backgroundColorArraycore}
                                    backgroundColorArraymanager={
                                        backgroundColorArraymanager
                                    }
                                    backgroundColorArraytalent={
                                        backgroundColorArraytalent
                                    }
                                    categoryImageArray={categoryImageArray}
                                    catgoryArray={
                                        profilemeData &&
                                        profilemeData?.data &&
                                        profilemeData?.data &&
                                        profilemeData?.data.categoryArr
                                    }
                                />
                            ) : null}
                            {Array.isArray(taxonomyData) && taxonomyData.length ? (

                                <div className="associated-traits container  mt-5">
                                    <p className="mb-0 text-white text-center">Validated behavioural skill cloud</p>
                                    <div className="d-flex row justify-content-between">
                                        <ul className="associate-list align-items-start d-flex  col-lg-10 justify-content-center flex-wrap">
                                            {taxonomyData.map((items, index) => {
                                                return (
                                                    <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>{items.name}</span></li>
                                                )
                                            })}
                                        </ul>


                                    </div>
                                </div>
                            ) : null}

                        </div>
                        <div className='col-md-6 col-lg-5 progress-report-container'>
                            <div className='d-flex justify-content-between'>
                                <h3>Skill breakdown</h3>
                            </div>
                            <div className="category-progress-summary category__block2 mt-4 pb-2">
                                {/* {
                        assessmentdetails.attempt === 1 && assessmentdetails.assessment_successfully_completed === 1 ? null
                            :
                            <button
                                onClick={() => setModalShow(true)}
                                className="btn px-4 py-2  mb-3 ml-auto mr-0 d-flex align-self-end border-2 border-color-white text-white btn-circle-30 text-center"
                            >
                                {toggle === true ? (
                                    <>
                                        <div className="d-flex">
                                            <img
                                                src={Images.close_blue}
                                                className="close_icon mr-3"
                                            />
                                            <span> Close history </span>
                                        </div>
                                    </>
                                ) : (
                                    <span>Read Lumen History</span>
                                )}
                            </button>
                    } */}
                                {categoryArray !== null &&
                                    categoryArray !== undefined &&
                                    categoryArray.length > 0 &&
                                    categoryArray.map((item, index) => {
                                        let activeLearningIcon = "";
                                        switch (item.categoryName) {
                                            case "Problem Solving":
                                                activeLearningIcon = Images.problem_solving;
                                                break;
                                            case "Critical Thinking":
                                                activeLearningIcon = Images.criticalthink_white;
                                                break;
                                            case "Teamwork":
                                                activeLearningIcon = Images.teamwork_white;
                                                break;
                                            case "Communication":
                                                activeLearningIcon = Images.comms_white;
                                                break;
                                            case "Leadership":
                                                activeLearningIcon = Images.leadershp_white;
                                                break;
                                            case "Time Management":
                                                activeLearningIcon = Images.timemgt_white;
                                                break;
                                            case "Emotional Judgement":
                                                activeLearningIcon =
                                                    Images.emotionaljudge_white;
                                                break;
                                            case "Creativity":
                                                activeLearningIcon = Images.creativity_white;
                                                break;
                                            case "Flexibility/Adaptability":
                                                activeLearningIcon = Images.Felixibility_inactive;
                                                break;

                                            default:
                                                activeLearningIcon = Images.emotionaljudge;
                                        }
                                        // console.log("itemsssssdata", item);
                                        return (
                                            <ProgressCard
                                                data={item}
                                                softSkillUrl={{
                                                    pathname:
                                                        "/pathway/soft-skill-explained-detail/" +
                                                        item.categoryIconName,
                                                    state: { data: item },
                                                }}
                                                icon={activeLearningIcon}
                                                title={item.categoryName}

                                                talentSection={item.highlightCategoryTalentedSection}
                                                managerialSection={item.highlightCategoryManagerialSection}
                                                coreSection={item.highlightCategoryCoreSection}

                                                percent={
                                                    item.categoryPercentage &&
                                                    item.categoryPercentage.slice(
                                                        0,
                                                        item.categoryPercentage.length - 1
                                                    )
                                                }
                                                categoryCorePercentage={
                                                    item.categoryCorePercentage &&
                                                    item.categoryCorePercentage.slice(
                                                        0,
                                                        item.categoryCorePercentage.length - 1
                                                    )
                                                }
                                                categoryManagerialPercentage={
                                                    item.categoryManagerialPercentage &&
                                                    item.categoryManagerialPercentage.slice(
                                                        0,
                                                        item.categoryManagerialPercentage.length - 1
                                                    )
                                                }
                                                categoryTalentedPercentage={
                                                    item.categoryTalentedPercentage &&
                                                    item.categoryTalentedPercentage.slice(
                                                        0,
                                                        item.categoryTalentedPercentage.length - 1
                                                    )
                                                }
                                                type={'text'}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                </>
            )}
            <CategoryShowList />

            <div>

            </div>

            <div className='read-lumen'>
                <div className='container'>
                    <h3>
                        How to read a lumen
                    </h3>
                    <p className='description'>
                        The different levels and how to improve your skills
                    </p>
                    <ul className='d-flex justify-content-row'>
                        <li className="d-flex align-items-top">
                            <figure>
                                <img src={trainingrequire} alt="" />
                            </figure>
                            <div>
                                <h4>
                                    Training Required
                                </h4>
                                <p>
                                    Context-based refresher required in this context
                                </p>
                            </div>
                        </li>
                        <li className="d-flex align-items-top">
                            <figure>
                                <img src={leveltwo} alt="" />
                            </figure>
                            <div>
                                <h4>
                                    Management Capability
                                </h4>
                                <p>
                                    Evaluative Expertise
                                </p>
                            </div>
                        </li>
                        <li className="d-flex align-items-top">
                            <figure>
                                <img src={twolevelinfo} alt="" />
                            </figure>
                            <div>
                                <h4>
                                    Company Benchmark Capability
                                </h4>
                                <p>
                                    Analytical Expertise
                                </p>
                            </div>
                        </li>
                        <li className="d-flex align-items-top">
                            <figure>
                                <img src={levelthree} alt="" />
                            </figure>
                            <div>
                                <h4>
                                    Talented
                                </h4>
                                <p>
                                    Brilliant Expertise
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className='breakdown-skills-container'>
                <div className='breakdown-skills container'>

                    <div className="skills-summary-report key-points-container">
                        <div className="d-flex row justify-content-between">
                            <div className="col-12 pr-2">
                                <p className="font-weight-bold sub-title">Behavioural Skills</p>
                                <ul className="pl-0 key-point-list list-unstyled">
                                    <li>
                                        <span className="col-12 px-0 text-white">Talented - Brilliant Expertise</span>
                                        <p className="col-12 text-white px-0 mt-2">This is considered to be an above excellent benchmark of behavioural skill capability. This demonstrates that a candidate is independent and able to think on their toes, has the capability to ensure that others in the workforce will perform at their best too, and can also effectively draw these effective qualities together to strategically progress the day to day and overall objectives within this business context.</p>
                                    </li>
                                    <li>
                                        <span className="col-12 px-0 text-white">Management Capability - Evaluative Expertise</span>
                                        <p className="col-12 text-white px-0 mt-2">This is considered to be an excellent benchmark of behavioural skill capability, that indicates that the candidate is very effective, independent and able to think on their toes, whilst possessing the necessary behavioural skills to ensure that others in the workforce will perform at their best too.</p>
                                    </li>
                                    <li>
                                        <span className="col-12 px-0 text-white">Company Benchmark Capability - Analytical Expertise</span>
                                        <p className="col-12 text-white px-0 mt-2">This is considered to be a baseline benchmark of behavioural capability if you want a workforce that is independent, thinks on their toes and has strong analytical skills.</p>
                                    </li>
                                    <li>
                                        <span className="col-12 px-0 text-white">Training Required – Context-based refresher required in this context</span>
                                        <p className="col-12 text-white px-0 mt-2">This is a context-based assessment and the candidate needs training or a refresher on what effective workplace behaviours are in the context of this assessment.</p>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-12 questionnaires">
                                <p className="sub-title">Lumenai questionnaires measure 4 levels of candidate identification of effective workplace behaviours in different contexts.</p>
                                <ul className="pl-0 key-point-list">
                                    <li className="d-flex">
                                        <span className="col-2 col-lg-1 px-0 text-white">Level 1</span>
                                        <p className="col-10  text-white">These questionnaires are good for talent identification or acquisition and are levelled for early school leavers, undergraduate level, long-term unemployed, English as a second language, prison leavers and those without extensive experience of the workplace.</p>
                                    </li>
                                    <li className="d-flex">
                                        <span className="col-2 col-lg-1 px-0 text-white">Level 2</span>
                                        <p className="col-10  text-white">These questionnaires are good for talent identification or acquisition, levelled for postgraduates without extensive experience of the workplace.</p>
                                    </li>
                                    <li className="d-flex">
                                        <span className="col-2 col-lg-1 px-0 text-white">Level 3</span>
                                        <p className="col-10  text-white">These questionnaires are good for talent identification or acquisition, levelled for employees or candidates with 5-10 years’ experience in the workplace, who are ready for greater levels of responsibility and promotion. You can also deploy these questionnaires for Level 1 and 2 candidates that you have a ‘hunch’ about and want to explore further.</p>
                                    </li>
                                    <li className="d-flex">
                                        <span className="col-2 col-lg-1 px-0 text-white">Level 4</span>
                                        <p className="col-10  text-white">These questionnaires are good for talent identification or acquisition, at Senior Strategic level. These are levelled for employees or candidates with 10+ years’ experience in the workplace, who are already in, or ready for C-Suite roles, or Level 1 and 2 candidates that you have a ‘hunch’ about and want to explore further.</p>
                                    </li>
                                </ul>
                            </div>



                        </div>
                    </div>




                </div>
            </div>
            <div className='template score-container'>
                <div className='container'>

                    <div className="col-12 pr-0 summary-report-container">
                        <p className="text-white sub-title">Summary report</p>
                        <ul className="pl-0 key-point-list list-unstyled">
                            <li>
                                <span className="col-12 px-0 text-white summary-score-title">First attempt - {assessmentdetails.questionBankOverallPercentage} score <i>({
                                    assessmentRange <= 100 && assessmentRange >= 90 ? "Exceptional"
                                        : assessmentRange <= 89 && assessmentRange >= 70 ? "Excellent"
                                            : assessmentRange <= 69 && assessmentRange >= 50 ? "Very Good" :
                                                "Concern"
                                })</i></span>
                                {/* <p className="col-12 text-white px-0 mt-2"> {usedetails && usedetails.name} is exceptional. He is a genius in strategic vision and goal setting contexts and should be hired or promoted immediately.</p> */}
                            </li>
                            {Array.isArray(talentCategory) && talentCategory.length ? (

                                <li>
                                    <span className="col-12 px-0 text-white">Talented</span>
                                    <p className="col-12 text-white px-0 mt-2">Brilliant expertise in

                                        <b> {
                                            talentCategory.map((items, index) => {
                                                return items.highlightCategoryTalentedSection ? items.categoryName + (talentCategory.length - 2 === index && talentCategory.length > 1 ? " and " : talentCategory.length > 1 && talentCategory.length !== index + 1 ? ", " : "") : null;
                                            })}</b>  in the context of <b>{assessmentdetails.questionBankName}</b>


                                    </p>
                                </li>)
                                : null}
                            {console.log(managementCategory, 'category')}

                            {Array.isArray(managementCategory) && managementCategory.length ? (
                                <li>
                                    <span className="col-12 px-0 text-white">Management Capability</span>
                                    <p className="col-12 text-white px-0 mt-2">Evaluative expertise in

                                        <b> {managementCategory.map((items, index) => {
                                            return items.highlightCategoryManagerialSection ? items.categoryName + (managementCategory.length - 2 === index && managementCategory.length > 1 ? " and " : managementCategory.length > 1 && managementCategory.length !== index + 1 ? ", " : "") : null;
                                        })}</b> in the context of <b>{assessmentdetails.questionBankName}</b></p>
                                </li>
                            ) : null}
                            {console.log(managementCategory, 'category')}
                            {Array.isArray(coreCategory) && coreCategory.length ? (

                                <li>
                                    <span className="col-12 px-0 text-white">Company Benchmark Capability</span>
                                    <p className="col-12 text-white px-0 mt-2">Analytical expertise in
                                        <b> {coreCategory.map((items, index) => {
                                            return items.highlightCategoryCoreSection ? items.categoryName + (coreCategory.length - 2 === index && coreCategory.length > 1 ? " and " : coreCategory.length > 1 && coreCategory.length !== index + 1 ? ", " : "") : null;
                                        })}</b> in the context of <b>{assessmentdetails.questionBankName}.</b></p>
                                </li>
                            ) : null}
                            <li>
                                <span className="col-12 px-0 text-white">Training required</span>
                                {Array.isArray(trainingCategory) && trainingCategory.length ? (
                                    <p className="col-12 text-white px-0 mt-2">
                                        <b> {trainingCategory.map((items, index) => {
                                            return items.categoryName ? items.categoryName + (trainingCategory.length - 2 === index && trainingCategory.length > 1 ? " and " : trainingCategory.length > 1 && trainingCategory.length !== index + 1 ? ", " : "") : null;
                                        })}</b> in the context of <b>{assessmentdetails.questionBankName}.</b></p>
                                ) : <p className="col-12 text-white px-0 mt-2">No training required</p>}
                            </li>
                        </ul>
                    </div>



                    <Link to="/email" className="button email mr-3">
                        Email report
                    </Link>
                    {/* <Link to={`/c/${clientId}?source=${clientUrl}`} className="button start-again">
                        Start again
                    </Link> */}

                </div>
            </div>

            {/* <FooterTemplate title="Learn more about lumenai" description="Nunc vestibuilum porta lacus, vel pulvinar massa rhoncus et." url="#" clientUserId={clientUserId} /> */}
        </div>
    )
}

export default ViewResults