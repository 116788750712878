import React, { useEffect, useState } from "react";
import { FooterTemplate, Template } from "../components";
import { API_ENDPOINT, AUTH_HEADERS, Images, data } from '../config';
import { useLocation, useNavigate } from "react-router";
import { getData } from "../utils";
import axios from "axios";

const Results = ({ route, navigate }) => {
    // const location = useLocation();
    // console.log(location);
    const navigated = useNavigate();
    const [resultData, setResultData] = useState({});
    const [userReport, setUserReport] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getData('resultData').then((response) => { setResultData(response)})
    }, [])

    useEffect(() => {
        getData('clientId').then((clientDataId) => {
            getData('clientUserId').then((clientUserId) => {
                getData('resultData').then((res) => {
                    getData('paymentSucceed').then((succeed) => {
                        if (res.payWall === 1 && succeed === true) {
                            axios.get(`${API_ENDPOINT}/third-party/assessment/user/${clientUserId}/paymentStatus`, {
                                headers: {
                                    ...AUTH_HEADERS,
                                    'clientId': clientDataId,
                                }
                            }).then((response) => response.status === 200 ? setLoader(false) : setLoader(true)
                                .catch((error) => {
                                    setLoader(true)
                                }))
                        } 
                        else if(res.payWall === 1 && succeed === false) {
                            navigated('/paywall');
                        } else {
                            setLoader(false);
                        }
                    })
                    
                }).catch((error) => {
                    navigated('/data-capture');        
                })
            }).catch((error) => {
                navigated('/user-details');        
            })

        }).catch((error) => {
            navigated('/');
        })

    }, [])


    return (
        <>
            {loader ? (<div className="loader"><h1> Loading......</h1></div>) : (
                <>
                    <div className='result-template'>
                        <Template
                            title={'Thank you - it’s time for your results'}
                            description={'Please select whether to view your report live or have the report sent to you via email.'}
                            type={'result'}
                            footer={true}
                            emailUpdate={resultData}
                        />
                    </div>

                    <FooterTemplate title="Learn more about lumenai" description="Nunc vestibuilum porta lacus, vel pulvinar massa rhoncus et." url="#" />
                </>
            )}

        </>
    )
}

export default Results;